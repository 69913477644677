import React, { Suspense, useEffect } from 'react';
import { Box, Model, useCanvas, useEngine, useScene } from 'react-babylonjs';
import { Color3, Vector3 } from '@babylonjs/core';


export const AvatarModel = (props) => {
    const engine = useEngine();
    const canvas = useCanvas();
    const scene = useScene();
    const { baseUrl, slug } = props;

    useEffect(() => {
        console.log('Avatar Scene', { engine, canvas, scene });
        canvas.addEventListener("wheel", evt => evt.preventDefault());
        return _ => {
            canvas.removeEventListener("wheel", evt => evt.preventDefault());
        }
    }, [])

    return (
        <>
        
            {/* cam._panningMouseButton = null; */}
            <arcRotateCamera name="camera"
                target={new Vector3(0, -0.1, 0)}
                alpha={Math.PI / 2}
                beta={Math.PI / 3}
                upperBetaLimit={1.50}
                lowerBetaLimit={1.50}
                lowerRadiusLimit={1.5}
                upperRadiusLimit={3}
                radius={2} 
                wheelPrecision={100}
                panningAxis={new Vector3.Zero()}
                />
            <hemisphericLight name='light' direction={Vector3.Up()} intensity={2} />

            <Suspense fallback={<LoaderBox />}>
                <Model
                    scaleToDimension={0}
                    position={new Vector3(0, -1.5, 0)}
                    rootUrl={`${baseUrl}`}
                    sceneFilename={`${slug}.glb`}
                    pluginExtension={props.fileExtension}
                    rotation={new Vector3(0, 0, 0)}
                />
            </Suspense>
        </>
    );
}

const LoaderBox = () => {
    return (
        <Box key="back" name="boxBack" height={0.005} width={1} depth={0.005}
            position={new Vector3(0, 0, -0.016)} 
        />
    );
};
