import Liz from './avatares/Liz.png'
import Ruli from './avatares/Ruli.png'
import Mimo from './avatares/Mimo.png'
import Tato from './avatares/Tato.png'
import Jenny from './avatares/Jenny.png'
import Fer from './avatares/Fer.png'
import Karen from './avatares/Karen.png'
import Nani from './avatares/Nani.png'
import Jorge from './avatares/Jorge.png'
import JoseLuis from './avatares/JoseLuis.png'
import Ale from './avatares/Ale.png'
import Betoral from './avatares/Betoral.png'
import Edwin from './avatares/Edwin.png'
import Kat from './avatares/Kat.png'
import Karina from './avatares/Karina.png'
import Adri from './avatares/Adri.png'
import Emma from './avatares/Emma.png'
import Gio from './avatares/Gio.png'
import Dani from './avatares/Dani.png'

// import Jenny from './avatares/Alien.png'
// import Fer from './avatares/Alien.png'
// import Karen from './avatares/Alien.png'
// import Emma from './avatares/Alien.png'

export const liz = Liz;
export const ruli = Ruli;
export const mimo = Mimo;
export const tato = Tato;
export const jenny = Jenny;
export const fer = Fer;
export const karen = Karen;
export const nani = Nani;
export const jorge = Jorge;
export const joseluis = JoseLuis;
export const ale = Ale;
export const betoral =Betoral;
export const edwin = Edwin;
export const kat = Kat;
export const karina = Karina;
export const adri = Adri;
export const emma = Emma;
export const gio = Gio;
export const dani = Dani;
