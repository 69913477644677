import {React, useCallback} from 'react'
import styles from './Contacto.module.css'
import facebook from '../../assets/img/contacto/facebook.svg'
import linkedin from '../../assets/img/contacto/linkedin.svg'
import Form from '../form/Form'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Framer from '../frameVariants/FrameVariants'; 


export default function Contact(props) {

    const {forwardRef}=props;
    const [inViewRef, inView] = useInView({ threshold: .6 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);

    return (
        <div ref={ setRefs } className={styles.wrapperContact}>
            <motion.h1
            variants = { Framer.FadeIn }
            initial = "hidden"
            animate= {inView ? "visible" : ""}
            transition={ Framer.HalfDelay }>
                Contacto</motion.h1>
            <div className={styles.contact}>
                <motion.div className={styles.contactData}
                variants = { Framer.FromBottom }
                initial = "hidden"
                animate = {inView ? "visible" : ""}
                transition ={ Framer.HalfDelay }>
                    <p>Huasteca No.197 Col. Industrial. CP. 07800. CDMX, México</p>
                    {/* <p>Teléfono: 55 55 555555</p> */}
                    <p>e-mail: contacto@codesign.mx</p>
                    <div className={styles.socialIcons}>
                        <div className={styles.contactIcon}><img src={facebook} alt="Facebook"/></div>
                        <div className={styles.contactIcon}><img src={linkedin} alt="LinkedIn"/></div>
                    </div>
                </motion.div>
                <motion.div className={styles.formContainer}
                variants = { Framer.FromBottom }
                initial = "hidden"
                animate = {inView ? "visible" : ""}
                transition ={ Framer.HalfDelay }>
                    <Form/>
                </motion.div>
                
            </div>
            <div className={styles.avisoPriv}>
                <a href='/avisoprivacidad-codesign.pdf'>Aviso de privacidad</a><p> | </p><a href='/terminosycondiciones-codesign.pdf'>Términos y condiciones</a> 
            </div>
            
        </div>
    )
}
