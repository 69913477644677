import React, { useEffect, useState } from 'react'
import styles from './Nav.module.css'
import { Link } from 'react-router-dom';
import { URL_SERVER } from '../utils/Utils';

export default function MenuRigth({ open, setOpen }) {

    const [data, setData] = useState({});

    useEffect(() => {
        getDataQuienes();
    }, [])

    async function getDataQuienes() {
        try {
            const response = await fetch(URL_SERVER + '/seccions', {
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                return data;
            });

            response.sort((a,b)=>{
                if(a.order<b.order){
                    return -1
                }else if(a.order>b.order){
                    return 1
                }else{
                    return 0
                }
            })

            setData(response)

        } catch (e) {
            console.log(e);
        }

    }


    return (
        <ul className={(open) ? styles.ulNav : styles.ulNavActive}>
            {
                (data.length > 0) &&
                <>
                    {
                        data.map(e => (
                            <li onClick={() => setOpen(!open)} key={e.slug}><Link to={{ pathname: '/', state: e.slug }}><p>{e.menu}</p></Link></li>
                        ))
                    }
                </>
            }
        </ul>
    )
}
