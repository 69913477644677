import React from "react";
import ReactPlayer from "react-player";
import wave from '../../assets/wave.mp4';
import flair from '../../assets/img/flair.svg';

export const URL_SERVER = "https://apiweb.codesign.mx";


export function BACK_FLAIR(props) {
    const {forwardRef}=props;

    return (
        <div className="backgroundFlair">
            <img ref={forwardRef} src={flair} alt="flair-background" />
        </div>
    )
}
export function VIDEO_WAVE() {
    return (
        <ReactPlayer className="backgroundVideo" url={wave} width='100%' height='100%' playing loop muted />
    )
}