import { createContext, useState } from "react";

export const CardContext = createContext();
export const CardProvider = props => {
    const [section, setSection] = useState(null);
    return (
        <CardContext.Provider value={[section, setSection]}>
            {props.children}
        </CardContext.Provider>
    )
}