import React from 'react'
import styles from './Nav.module.css'

export default function Burger({ open, setOpen }) {

    return (
        <div className={(open) ? styles.burger : styles.burgerUnactive} onClick={() => setOpen(!open)}>
            <div className={styles.barBurger}></div>
            <div className={styles.barBurger}></div>
            <div className={styles.barBurger}></div>
        </div>
    )
    
}
