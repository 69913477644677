import React, { useEffect, useState } from 'react'
import styles from './Form.module.css'
import userIcon from '../../assets/img/contacto/user.svg'
import mailIcon from '../../assets/img/contacto/mail.svg'
import celIcon from '../../assets/img/contacto/cel.svg'
import sendIcon from '../../assets/img/contacto/send.svg'
import { validateEmail, validatePhone } from '../utils/helpers'
import { URL_SERVER } from '../utils/Utils'

const updateError = (error, stateUpdater) =>{
    stateUpdater(error)
    setTimeout(()=>{
        stateUpdater('')
    },2000)
}

export default function Form() {

    const [terms, SetTerms] = useState(false)

    const [dataContact, SetDataContact] = useState({
        nombre:  '',
        email: '',
        telefono: '',
        terminos: terms
    })

    const [error, setError] = useState('')
    const [isSended, setIsSended] = useState('')

    const onChange = (e) =>{
        SetDataContact({...dataContact, [e.target.name] : e.target.value})
    }

    const toggleCheck = () => {
        
        SetTerms(!terms)
        SetDataContact({...dataContact, terminos: terms})
        
    }

    const validateData = () => {
            let isValid = true
            if(!dataContact.nombre.trim() || dataContact.nombre.length < 0){
                updateError('Ingresa tu nombre', setError)
                isValid = false
            }
            if(!dataContact.email.trim() || !validateEmail(dataContact.email)){
                updateError('Ingresa email válido', setError)
                console.log('ingresa mail válido');
                isValid = false
            }
            if(!dataContact.telefono.trim() || !validatePhone(dataContact.telefono) || dataContact.telefono.length < 10){
                updateError('Ingresa un teléfono válido', setError)
                console.log('ingresa un teléfono válido');
                isValid = false
            }
            if(!terms){
                updateError('Acepta los términos y condiciones', setError)
                console.log('acepta los terminos y condiciones');
                isValid = false
            }
            return isValid
    }

    const onSubmit = async () => {
        if(!validateData()){
            console.log('Error al enviar');
            return
        } else{
            const params ={
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...dataContact, terminos: terms})
            }
                fetch(`${URL_SERVER}/correos`, params)
                    .then(resp => resp.json())
                    .catch(error => {
                        updateError('Error al registrar datos', setError)
                        console.log(error);
                    })
                    .then(data =>{ 
                        console.log(data.updatedAt)
                        updateError('Enviamos tus datos, Gracias!', setIsSended) 
                        SetTerms(false)
                        SetDataContact({
                            nombre:  '',
                            email: '',
                            telefono: '',
                            terminos: terms
                        })
                    })
                console.log('valid form');
        }
    }

    return (
        <div className={styles.form}>
            {
               error ? <div><p className={styles.errorTxt}>{error}</p></div> : null
            }
            {
               isSended ? <div><p className={styles.sendTxt}>{isSended}</p></div> : null
            }
            <label>Nombre completo</label>
            <div className={styles.inputBox}>
                <input type="text" name="nombre" onChange={onChange} value={dataContact.nombre} placeholder="Nombre completo" />
                <img src={userIcon} alt="user"/>
            </div>
            <label>E-mail</label>       
            <div className={styles.inputBox}>
                <input type="text" name="email" onChange={onChange} value={dataContact.email} placeholder="Correo electrónico"/>
                <img className={styles.iconMail} src={mailIcon} alt="user"/>
            </div>
            <label>Teléfono</label>
            <div className={styles.inputBox}>
                <input type="text" name="telefono" onChange={onChange} value={dataContact.telefono} placeholder="Teléfono o celular"/>
                <img src={celIcon} alt="user"/>
            </div>
            <div className={styles.wrapperChecks}>
                <div onClick={toggleCheck} className={styles.contentCheck}>
                    {
                       terms && <div className={styles.customCheck}></div>
                    }
                </div>
                <label>Acepto términos y condiciones</label>
            </div>
            <div className={styles.btnBox}>
                <button onClick={onSubmit}>Enviar</button>
                <img src={sendIcon} alt="send"/>
            </div>
        </div>
    )
}
