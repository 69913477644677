import { React, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './AliadoTec.module.css';
import video from '../../assets/videoBetaopt.mp4';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Framer from '../frameVariants/FrameVariants'; 


function AliadoTec(props) {

    const { forwardRef } = props;
    const [inViewRef, inView] = useInView({ threshold: .6 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);


    return (

        <div ref={setRefs} className={styles.wrapperAlidado}>
            <motion.div className={styles.videoWrapper}
            variants={Framer.FadeIn}
            initial="hidden"
            animate={inView ? "visible" : ""}
            transition={Framer.HalfDelay}>
                <ReactPlayer className={styles.wrapperVideo} url={video} loop playing muted />
            </motion.div>
            <motion.div className={styles.wrapperTitle} 
                variants={Framer.FadeIn}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                <h1>Codesign como <br />aliado tecnológico</h1>
            </motion.div>

            <motion.div className={styles.wrapperText}
                variants={Framer.FromLeft}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                <h2>Existe una oferta tecnológica muy amplia, que más allá de ayudar genera confusión. Esto puede provocar que se utilicen muchas herramientas para resolver una necesidad.</h2>
            </motion.div>

            <motion.div className={styles.wrappertable}
                variants={Framer.FromBottom}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                <div>
                    <p>La tecnología es una herramienta, lo importante está en lo que se quiere lograr, cómo se va a lograr y quién la va a usar.</p>
                </div>
                <div>
                    <p>Implementar tecnología no debe generar más trabajo, debe optimizar tareas.</p>
                </div>
                <div>
                    <p>Te acompañamos en la adopción y sustentabilidad de elementos tecnológicos clave.</p>
                </div>
            </motion.div>
        </div>

    );
}
export default AliadoTec;