import React, { useEffect, useState } from 'react';
import Nav from '../nav/Nav';
import { BACK_FLAIR, URL_SERVER, VIDEO_WAVE } from '../utils/Utils';
import styles from './Portafolio.module.css';
import ReactPlayer from 'react-player';
import Error404 from '../Error404';
function Portafolio(props) {
    const [data, setData] = useState({});
    const [media,setMedia] = useState({
        content: null,
    })
    let _url = props.match.url;

    useEffect(() => {
        let _proyecto = _url.replace('/portafolio/', '')
        getPortafolio(_proyecto)
    }, [_url])

    async function getPortafolio(slug) {
        try {
            const response = await fetch(URL_SERVER+'/proyectos?slug='+slug, {
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                return data;
            });
           
            setData(response[0]);

        } catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        
        if(data !== undefined)
            if(Object.keys(data).length>0){
                let _media = null;
                
                _media = <div className={styles.wrappermedia}><img src={data.imagen.url} alt={"imagen-proyecto"}/></div>
                if(data.video !== "")
                    _media = <ReactPlayer className={styles.wrapperVideo} url={data.video} playing loop muted onError={() => {
                        setMedia(prevState=>({...prevState,content: _media}));
                    }}/>

                    setMedia(prevState=>({...prevState,content: _media}))
                
            }

    },[data])

    
    if(data === undefined){
        return(
            <Error404 />
        );
    }else{
        return (
            <div className={styles.wrapper}>
                <Nav />
                <div className={styles.wrapperTitle}>
                    <h1>{data.titulo}</h1>
                </div>
                <div className={styles.mediaCont}>
                    <div className={styles.mediaText}>
                        <div className={styles.wrapperSubTitle}>
                            <h2>{data.descripcion}</h2>
                        </div>
                        <div className={styles.line}></div>
                        <div className={styles.wrapperDescription}>
                            <p>{data.contenido}</p>
                        </div>
                    </div>
                    <div className={styles.mediaVideo}>
                        {
                            media.content
                        }
                        <BACK_FLAIR />
                        <VIDEO_WAVE />
                    </div>
                </div>
            </div>
        )
    }
}
export default Portafolio;