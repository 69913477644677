import {React, useContext, useCallback} from 'react'
import styles from './Herramientas.module.css'
import * as Iconos from '../../assets/Icons'
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion'
import * as Framer from '../frameVariants/FrameVariants'

const Herramientas = ( props ) =>{

    const { forwardRef } = props;
    const [inViewRef, inView] = useInView({ threshold: .2 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);

    return(
        <div ref={ setRefs } className={styles.induWrap}>
            <motion.div className={styles.titleCont}
                variants={Framer.FromBottom}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                <h1 className={styles.title}>Herramientas digitales <br/> <span>para el sector inmobiliario</span> </h1>
                <div className={styles.text}>
                    <p><span>Soluciones poderosas</span><br/>para promocionar tus espacios e inmuebles</p>
                </div>
            </motion.div>
            <motion.div className={styles.iconCont}
                variants={Framer.FadeIn}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                <div className={styles.icon}>
                    {Iconos.InmoIcon('#fff')}
                    <p>Inmobiliarias</p>
                </div>
                <div className={styles.icon}>
                    {Iconos.ArquiIcon('#fff')}
                    <p>Arquitectura</p>
                </div>
                <div className={styles.icon}>
                    {Iconos.BookIcon('#fff')}
                    <p>Booking</p>
                </div>
            </motion.div>
            <motion.a className={styles.induBtn}
            variants={Framer.FadeIn}
            initial="hidden"
            animate={inView ? "visible" : ""}
            transition={Framer.HalfDelay}
            href='https://realtytech.netlify.app/'
            target='_blank'>
                <p> Ver más </p>
            </motion.a>
        </div>
    )
}

export default Herramientas
