import React from 'react';
import styles from './TextLoader.module.css';
import LogoText from '../../assets/img/logoTxt.svg';
import LogoSlogan from '../../assets/img/logoSlogan.svg';
import { motion } from 'framer-motion';
import { FromBottom, FadeIn, NoDelay, Delay1s} from '../frameVariants/FrameVariants';


export default function TextLoader() {

    return(

        <div className={styles.logoWrapper}>
            <motion.div className = {styles.logo} 
            variants={ FromBottom }
            initial = "hidden"
            animate= "visible"
            transition={ NoDelay }>
                <img src = {LogoText}/>
            </motion.div>

            <motion.div className = {styles.slogan} 
            variants={ FadeIn }
            initial = "hidden"
            animate= "visible"
            transition={ Delay1s }>
                <img src = {LogoSlogan}/>
            </motion.div>
        </div>  
    );
    
    
}