// Animación de abajo hacia arriba

export const FromBottom = {
    hidden: { opacity: 0, y: 100},
    visible: { opacity: 1, y: 0},
}

// Animación de arriba hacia abajo

export const FromTop = {
    hidden: { opacity: 0, y: -100},
    visible: { opacity: 1, y: 0},
}

// Animación de derecha a izquierda

export const FromRight = {
    hidden: { opacity: 0, x: 200},
    visible: { opacity: 1, x: 0},
}

// Animación de izquierda a derecha

export const FromLeft = {
    hidden: { opacity: 0, x: -200},
    visible: { opacity: 1, x: 0},
}

// Animación FadeIn sencilla

export const FadeIn = {
    hidden: { opacity: 0},
    visible: { opacity: 1},
}

// Transiciones de animación

export const NoDelay = {
      ease: "easeOut", duration: 1 
}

export const HalfDelay = {
    ease: "easeOut", duration: .5 
}

export const Delay1s = {
    ease: "easeOut", duration: 1, delay: 1
}

export const Delay2s = {
    ease: "easeOut", duration: 1, delay: 2
}