import React, { useContext, useEffect, useState } from "react";
import styles from "./CardTeam.module.css";
import more from "./../../assets/img/team/more.svg"
import { CardContext } from "../../context/CardContext";
import Slider from "react-slick";
import * as Avatares from "../../assets/team/Avatares";
import * as Badges from "../../assets/team/Badges"
import RenderModel from "./RenderModel";

export default function CardTeam(props) {


    const { Toggle } = props;
    const { data } = props;
    const [globalCard, setGlobalCard] = useContext(CardContext)
    const [showMedal, setShowMedal] = useState(false)
    const [titles, setTitles] = useState([])
    const [currentT, setCurrentT] = useState(0)


    const settings = {
        centerMode: true,
        infinite: false,
        centerPadding: "0",
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        afterChange: (current) => setCurrentT(current),

    }

    function ShowMore() {
        if (globalCard === parseInt(data.order)) {
            setGlobalCard(null)
        } else {
            setGlobalCard(parseInt(data.order))
        }
        Toggle()
    }

    useEffect(() => {
        setShowMedal(false)
        if (parseInt(data.order) === globalCard) {
            setShowMedal(true)
        }
    }, [globalCard])
    useEffect(() => {
        if (data.badges) {

            data.badges.sort((a, b) => {
                if (parseInt(a.priority) < parseInt(b.priority)) {
                    return -1
                } else if (parseInt(a.priority) > parseInt(b.priority)) {
                    return 1
                } else {
                    return 0
                }
            })
            setTitles(data.badges.map(e => { return e.name }))
        }

    }, [])
    return (
        <div className={styles.wrapper}>
            <div>

                <div className={styles.name}>
                    <h2>{data.name}</h2>
                </div>
                <div className={styles.position}>
                    <h4>{data.position}</h4>
                </div>
                {
                    (showMedal) &&
                    <RenderModel slug={data.slug} />
                }
                {
                    (!showMedal) &&
                    <div className={styles.render}>
                        <img src={Avatares[data.slug]} alt={"avatar"} />
                    </div>
                }
                {
                    (showMedal) &&
                    <div className={`${styles.wrapperMedallas} ${(titles.length < 2) ? styles.one : ""}`}>
                        <div className={styles.wrapperSlideMedal}>
                            <Slider {...settings}>
                                {
                                    data.badges.map((e, i) => (<Medal style={{ width: 70 }} key={"medal" + i} data={e} />))
                                }
                            </Slider>
                        </div>
                        <div className={styles.titleMedalla}>
                            <h4>{titles[currentT]}</h4>
                        </div>
                    </div>
                }
                <div onClick={ShowMore} className={styles.btnMore}>
                    <img src={more} alt={"btn-more"} />
                </div>
            </div>
        </div>
    )
}

function Medal({ data }) {
    return (
        <div className={styles.hexagono}>
            <img src={Badges[`_${data.slug}`]} alt={data.slug} />
        </div>
    )
}

function NextArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_next}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}
function PrevArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_prev}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}