import React from 'react';
import Nav from './nav/Nav';
import { BACK_FLAIR, VIDEO_WAVE } from './utils/Utils';
import styles from './Error404.module.css';
import error from './../assets/img/error.svg';
import Lottie from 'react-lottie';
import Ufo from './../assets/animations/Ufo.json';

export default function Error404(){
    const paramsAlien ={
        renderder: 'svg',
        loop: true,
        autoplay: true,
        animationData: Ufo
    }
    return(
        <div className={styles.wrapper}>
            <Nav />
            <div className={styles.wrapperLottie}>
                <Lottie options={paramsAlien} />
            </div>
            <div className={styles.wrapperText}>
                <img src={error} alt='error-404'/>
                <h2>¡Úps...página no encontrada!</h2>
            </div>
            <BACK_FLAIR/>
            <VIDEO_WAVE/>
        </div>
    );
}