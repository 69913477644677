import {React, useCallback} from 'react'
import Bullet from './Bullet'
import styles from './Entendemos.module.css'
import entender from '../../assets/img/entendemos/entender.svg'
import retos from '../../assets/img/entendemos/retos.svg'
import necesidades from '../../assets/img/entendemos/necesidades.svg'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Framer from '../frameVariants/FrameVariants'; 

export default function Entendemos(props) {

    const {forwardRef}=props;
    const [inViewRef, inView] = useInView({ threshold: .6 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);


    return (
        <div ref={setRefs} className={styles.teEntendemos}>
            <motion.div className={styles.titleHero}
            variants = { Framer.FromLeft }
            initial = "hidden"
            animate = {inView ? "visible" : ""}
            transition ={ Framer.HalfDelay }>

                <h1>En Codesign te entendemos</h1>
            </motion.div>
            <motion.div className={styles.bulletsHero}
            variants = { Framer.FromRight }
            initial = "hidden"
            animate= {inView ? "visible" : ""}
            transition={ Framer.HalfDelay }>
                <Bullet
                    icon={entender}
                    text="El objetivo de las empresas es encontrar soluciones a problemas y necesidades, no el entender la tecnología."
                    alt="entender"
                />
                <Bullet
                    icon={retos}
                    text="Así como las personas enfrentan retos y barreras tecnológicas en su día a día, dentro de las empresas ocurre lo mismo a gran escala."
                    alt="retos"
                />
                <Bullet
                    icon={necesidades}
                    text="El camino correcto es hacer que la tecnología se adapte para resolver las necesidades y no verse obligados a adaptarse a las tecnologías con limitantes."
                    alt="necesidades"
                />
            </motion.div>
        </div>
    )
}
