import React from 'react';
import Lottie from 'react-lottie';
import styles from './Loader.module.css';
import Alien from '../../assets/animations/CubeBody.json';
import TextAnim from '../../assets/animations/TextLoader.json';
import TextLoader from './TextLoader';


function Loader() {

    const paramsAlien ={
        renderder: 'svg',
        loop: true,
        autoplay: true,
        animationData: Alien
    }
    const paramsTexto ={
        renderder: 'svg',
        loop: false,
        autoplay: true,
        animationData: TextAnim
    }
    return(
        <div className={styles.loaderWrapper}>
            <div className={styles.wrapperAlien}>
                <Lottie options={paramsAlien} />
            </div>
            <div className={styles.wrapperTexto}>
                {/* <Lottie options={paramsTexto} />     */}
                <TextLoader/>
            </div>
        </div>
    );
}

export default Loader;