import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './modules/home/Home'
import Portafolio from './modules/portafolio/Portafolio';
import Error404 from './modules/Error404';

function App() {
  return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/portafolio/:proyecto" exact component={Portafolio} />
          <Route path="*" component={Error404} />
        </Switch>
      </Router>
  );
}

export default App;
