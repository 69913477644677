import React from 'react'
import styles from './Entendemos.module.css'

export default function Bullet({icon, text, alt}) {
    return (
        <div className={styles.bullet}>
            <div className={styles.iconBullet}><img src={icon} alt={alt}/></div>
            <div className={styles.textBullet}><p>{text}</p></div>
        </div>
    )
}
