import React, { useEffect, useState, useCallback } from 'react';
import styles from './Proyectos.module.css';
import Slider from 'react-slick';
import { URL_SERVER } from '../utils/Utils';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Framer from '../frameVariants/FrameVariants'; 

function Proyectos(props) {

    const {forwardRef}=props;
    const [inViewRef, inView] = useInView({ threshold: .6 });
    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);

    const [data, setData] = useState([]);
    const [slidesToShow, setSlidesToShow] = useState(3);
    const [centerMode,setCenterMode] = useState(true)

    let setting_slider = {
        centerMode: centerMode,
        infinite: false,
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    }
    useEffect(() => {
        getDataProyectos();
    }, [])
    useEffect(()=>{
        window.addEventListener('resize', onResize)
        function onResize() {
            if(window.innerWidth<1024){
                    setCenterMode(false)
            }else{
                    setCenterMode(true)
            }
           
        }
        onResize()
        return _ => {
            window.removeEventListener('resize', onResize);
        }
    },[])
    async function getDataProyectos() {
        try {
            const response = await fetch(URL_SERVER + '/proyectos', {
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                return data;
            });

            setData(response)

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div ref={setRefs} className={styles.wrapperProyectos}>
            <motion.div className={styles.wrapperTitle}
            variants = { Framer.FadeIn }
            initial = "hidden"
            animate = {inView ? "visible" : ""}
            transition ={ Framer.HalfDelay }>
                <h1>Proyectos</h1>
            </motion.div>
            <motion.div className={styles.sliderCont}
            variants = { Framer.FromBottom }
            initial = "hidden"
            animate = {inView ? "visible" : ""}
            transition ={ Framer.HalfDelay }>

                <Slider {...setting_slider}>

                    {(data.length > 0) &&

                        data.map((e, i) => (<Slides key={"slide" + i} style={(centerMode)?{width: 400}:{width:'95vw'}} data={e} />))

                    }

                </Slider>
            </motion.div>


        </div>
    );
}

export default Proyectos;

function Slides(props) {
    const { data } = props;

    return (
        <a  href={"/portafolio/"+data.slug} >
            <div className={styles.slide}>
                <div style={{ backgroundImage: `url(${data.imagen.url})` }}>
                    <div className={styles.slideTextBox}>
                        <div className={styles.slideTitle}><p>{data.titulo}</p></div>
                        <div className={styles.slideText}><p>{data.descripcion}</p></div>
                        <div className={styles.slideLine}></div>
                    </div>
                </div>
            </div>
        </a>
    );
}


function NextArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_next}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}
function PrevArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_prev}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}