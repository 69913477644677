import __leadfront from './badges/FrontLead.svg'
import __3dev from './badges/3Ddeveloper.svg'
import __frontend from './badges/Front.svg'
import __unity from './badges/Unity.svg'
import __backend from './badges/Back.svg'
import __spmo from './badges/PM.svg'
import __ux from './badges/UX.svg'
import __pmo from './badges/PMO.svg'
import __ui from './badges/UI.svg'
import __ceo from './badges/CEO.svg'
import __webgl from './badges/WebGL.svg'
import __cto from './badges/CTO.svg'
import __lead3d from './badges/3DLead.svg'
import __cio from './badges/CIO.svg'
import __3dartist from './badges/3D.svg'
import __copy from './badges/Copy.svg'
import __multi from './badges/Multimedia.svg'
import __translator from './badges/Translator.svg'
import __designer from './badges/Designer.svg'
import __admin from './badges/Administration.svg'
import __sales from './badges/sales.svg'



export const _leadfront = __leadfront;
export const _3dev = __3dev;
export const _frontend = __frontend;
export const _unity = __unity;
export const _backend = __backend;
export const _spmo = __spmo;
export const _ux = __ux;
export const _pmo = __pmo;
export const _ui = __ui;
export const _ceo = __ceo;
export const _webgl = __webgl;
export const _cto = __cto;
export const _lead3d = __lead3d;
export const _cio = __cio;
export const _3dartist = __3dartist;
export const _copy = __copy;
export const _multi = __multi;
export const _translator = __translator;
export const _designer = __designer;
export const _admin = __admin;
export const _sales = __sales;
