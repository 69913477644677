import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import styles from './Nav.module.css';
import cdsgn from "../../assets/img/cdsgn.svg"
import Alien from '../../assets/animations/CubeBody.json';
import MenuRigth from './MenuRigth';
import Burger from './Burger';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FadeIn, Delay1s } from '../frameVariants/FrameVariants';

function Nav() {

    const [open, setOpen] = useState(true)

    const paramsAlien = {
        renderder: 'svg',
        loop: true,
        autoplay: true,
        animationData: Alien
    }

    useEffect(()=>{
        window.addEventListener('resize',onResize);
        function onResize(){
            if(window.innerWidth > 1024)
                setOpen(true)
        }
        onResize()
        return _ =>{
            window.removeEventListener('resize',onResize);
        }   
    },[])


    function changeOpen(val){
        if(window.innerWidth<1024){
            setOpen(val)
        }
    }


    return (
        <motion.div className={styles.navWrapper}
        variants={ FadeIn }
        initial = "hidden"
        animate= "visible"
        transition={ Delay1s }>
            
            <Link className={styles.navImg}
                to={{
                    pathname: '/',
                    state: 'home'
                }
                }>
                <div className={styles.navImg}>
                    <Lottie options={paramsAlien} />
                    <img src={cdsgn} alt="logo" />
                </div>
            </Link>
            <MenuRigth open={open} setOpen={changeOpen} />
            <Burger open={open} setOpen={setOpen} />
        </motion.div>
    );
}

export default Nav;

