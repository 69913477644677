import React, { useCallback, useEffect, useState } from 'react';
import styles from './QuienesSomos.module.css';
import monitor from '../../assets/img/quienes/monitor.jpg';
import Slider from 'react-slick';
import { URL_SERVER } from '../utils/Utils';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Framer from '../frameVariants/FrameVariants'; 


function QuienesSomos(props) {
    const {forwardRef}=props;
    const [inViewRef, inView] = useInView({ threshold: .6 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);

    const [state, setState] = useState({
        isMobile: false,
        component: null
    });
    const [data, setData] = useState([]);

    useEffect(() => {
        getDataQuienes();
    }, [])

    async function getDataQuienes() {

        const response = await fetch(URL_SERVER+'/bullets',{
            method: "get",
            headers: {
                "Content-Type": "application/json; charset=UTF-8"
            }
        }).then(function (response) {
            return response.json();
        })
        
        let myData = response;
        setData(myData)
    }

    useEffect(() => {
        window.addEventListener('resize', onResize);
        function onResize() {
            let _isMobile = false;
            if (window.innerWidth < 768)
                _isMobile = true;

            setState(prevState => ({
                ...prevState,
                isMobile: _isMobile
            }));
        }
        onResize();
        return _ => {
            window.removeEventListener('resize', onResize);
        }
    }, [])

    useEffect(() => {
        const setting_slider ={
            infinite:false,
            speed:500,
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>
         }
         let _component = null;
        if(data.length > 0 ){

            _component = <div className={styles.desktop}>{data.map((e,i)=>(<InfoCube key={"cube"+i} data={e}/>))}</div>
    
            if (state.isMobile)
                _component = <div className={styles.mobile}>
                    <Slider {...setting_slider}>{data.map((e,i)=>(<InfoCube key={"cube"+i} data={e}/>))}</Slider></div>
        
        }

        setState(prevState => ({
            ...prevState,
            component: _component
        }));

    }, [state.isMobile,data])



    return (
        <div ref={setRefs} className={styles.wrapperQuienes}>
            <div className={styles.wrapperInfo}>
                <motion.div className={styles.wrapperTitle}
                variants={Framer.FromRight}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                    <h1>Quiénes somos</h1>
                </motion.div>
                <motion.div className={styles.wrapperText}
                variants={Framer.FromRight}
                initial="hidden"
                animate={inView ? "visible" : ""}
                transition={Framer.HalfDelay}>
                    <h2>Somos una empresa de tecnología creativa que ayuda a las empresas a resolver necesidades en distintas áreas, desarrollando e implementando herramientas tecnológicas para potencializar sus negocios.</h2>
                </motion.div>
                {
                    (!state.isMobile) &&
                    <motion.div className={styles.wrapperImg}
                    variants={Framer.FadeIn}
                    initial="hidden"
                    animate={inView ? "visible" : ""}
                    transition={Framer.HalfDelay}>
                        <img src={monitor} alt="monitor" />
                    </motion.div>
                }
            </div>
            <motion.div className={styles.wrapperCubes}
            variants={Framer.FromLeft}
            initial="hidden"
            animate={inView ? "visible" : ""}
            transition={Framer.HalfDelay}>
                {state.component}
            </motion.div>
        </div>
    );
}
export default QuienesSomos;

function InfoCube(props) {
    const {data} = props;
    return (
        <div className={styles.cube}>
            <div className={styles.infoCube}>
                <div className={styles.icon}>
                    <div style={{WebkitMask:`url(${data.img.url})`}} alt={"icon-"+data.title} /> 
                </div>
                <div className={styles.title}>
                    <p>{data.title}</p>
                </div>
            </div>
            <div className={styles.text}>
                <p>{data.content}</p>
            </div>
        </div>
    )
}
function NextArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_next}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}
function PrevArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_prev}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}