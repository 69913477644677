import React, { useCallback, useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styles from './Team.module.css'
import * as Framer from '../frameVariants/FrameVariants';
import { motion } from "framer-motion";
import Slider from "react-slick";
import CardTeam from "./CardTeam";
import { URL_SERVER } from "../utils/Utils";
import { CardContext } from "../../context/CardContext";

export default function Team({ forwardRef }) {


    const [inViewRef, inView] = useInView({ threshold: .5 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);

    const [classSlider, setClassSlider] = useState("")
    const [data, setData] = useState([])
    const [,setGlobalCard] = useContext(CardContext)
    const [centerMode,setCenterMode] = useState(true)
    let setting_slider = {
        className: classSlider,
        centerMode: centerMode,
        infinite: false,
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => ChangeSlide(next),
    }
    function ChangeSlide(val) {
        
        if (classSlider.includes('more')) {
            setGlobalCard(val)
        }
    }
    function ToggleMore() {
        if (classSlider.includes('more')) {
            setClassSlider('')
        } else {
            setClassSlider('more')
        }
    }

    useEffect(()=>{
        window.addEventListener('resize', onResize)
        function onResize() {
            if(window.innerWidth<600){
                setCenterMode(false)
            }else{
                setCenterMode(true)
            }
           
        }
        onResize()
        return _ => {
            window.removeEventListener('resize', onResize);
        }
    },[])


    useEffect(() => {
        getTeam();
    }, [])
    async function getTeam() {
        try {
            const response = await fetch(URL_SERVER + '/partners', {
                method: 'get',
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(function (response) {
                return response.json();
            }).then(function (data) {
                return data;
            });
            response.sort((a, b) => {
                if (parseInt(a.order) < parseInt(b.order)) {
                    return -1
                } else if (parseInt(a.order) > parseInt(b.order)) {
                    return 1
                } else {
                    return 0
                }
            })
            setData(response)

        } catch (e) {
            console.log(e);
        }
    }


    return (
        <div ref={setRefs} className={styles.wrapper}>
            <div className={styles.title}>
                <motion.h1
                    variants={Framer.FadeIn}
                    initial="hidden"
                    animate={inView ? "visible" : ""}
                    transition={Framer.HalfDelay}>
                    Nuestro Equipo</motion.h1>
            </div>
            <div className={styles.wrapperSlider}>
                <Slider {...setting_slider}>
                    {/* {(data.length > 0) &&
                        data.map((e, i) => {
                            if(!e.name.includes("Pons") && !e.name.includes("Magaña") && !e.name.includes("Karen") && !e.name.includes("Emmanuel") ){
                                return <CardTeam key={"team" + i} data={e} style={(centerMode)?{width: 400}:{width:'95vw'}} Toggle={ToggleMore} />
                            }
                        })
                    } */}
                    {(data.length > 0) &&
                        data.map((e, i) => (<CardTeam key={"team" + i} data={e} style={(centerMode)?{width: 400}:{width:'95vw'}} Toggle={ToggleMore} />))
                    }
                </Slider>
            </div>
        </div>
    )
}
function NextArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_next}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}
function PrevArrow(props) {
    const { className, onClick } = props;
    let classArrow = `${className} ${styles.icon_arrow} ${styles.arrow_prev}`;
    return (
        <div
            className={classArrow}
            onClick={onClick}
        />
    );
}