import React, { useEffect, useRef, useState } from 'react';
import styles from './Home.module.css';
import Nav from '../nav/Nav';
import Loader from '../loader/Loader';
import AliadoTec from '../aliado/AliadoTec';
import QuienesSomos from '../quienes/QuienesSomos';
import Proyectos from '../proyectos/Proyectos';
import Entendemos from '../entendemos/Entendemos';
import Contact from '../contacto/Contact';
import Tecnologia from '../tecologia/Tecnologia';
import Herramientas from '../herramientas/Herramientas';
import { BACK_FLAIR, VIDEO_WAVE } from '../utils/Utils';
import Team from '../team/Team';
import { CardProvider } from '../../context/CardContext';

function Home(props) {

    const [loader, setLoader] = useState(true);
    const refFlair = useRef(null);
    const refEntendemos = useRef(null);
    const refAliado = useRef(null);
    const refQuienes = useRef(null);
    const refProyectos = useRef(null);
    const refHerramientas = useRef(null);
    const refTecnologia = useRef(null);
    const refTeam = useRef(null);
    const refContacto = useRef(null);
    const stateLocal = props.location.state;


    useEffect(() => {

        if (stateLocal === undefined) {
            setTimeout(() => {
                setLoader(false)
            }, 7000)
        } else {
            let scrollY = 0;
            setLoader(false);

            setTimeout(() => {

                let _entendemos = refEntendemos.current.getBoundingClientRect().height;
                let _aliado = refAliado.current.getBoundingClientRect().height;
                let _quienes = refQuienes.current.getBoundingClientRect().height;
                let _proyectos = refProyectos.current.getBoundingClientRect().height;
                let _tecnologia = refTecnologia.current.getBoundingClientRect().height;
                let _herramientas = refHerramientas.current.getBoundingClientRect().height;
                let _team = refTeam.current.getBoundingClientRect().height;



                switch (stateLocal) {
                    case 'home':
                        scrollY = 0;
                        break;
                    case 'entendemos':
                        scrollY = 0;
                        break;
                    case 'aliado':
                        scrollY = _entendemos - 100;
                        break;
                    case 'quienes':
                        scrollY = _entendemos + _aliado - 100;
                        break;
                    case 'tecnologia':
                        scrollY = _entendemos + _aliado + _quienes - 100;
                        break;
                    case 'herramientas':
                        scrollY = _entendemos + _aliado + _quienes + _tecnologia -100;
                        break;
                    case 'proyectos':
                        scrollY = _entendemos + _aliado + _quienes + _tecnologia + _herramientas - 100;
                        break;
                    case 'equipo':
                        scrollY = _entendemos + _aliado + _quienes + _proyectos + _herramientas + _tecnologia - 100;
                        break;                        
                    case 'contacto':
                        scrollY = _entendemos + _aliado + _quienes + _proyectos + _herramientas +  _tecnologia + _team - 100;
                        break;
                    default:
                        scrollY = 0;
                        break;
                }
                window.scrollTo(0, scrollY)
            }, 500)

        }



    }, [stateLocal])


    useEffect(() => {

        window.addEventListener('scroll', OnScroll)
        function OnScroll() {

            let _entendemos = refEntendemos.current.getBoundingClientRect().height;
            let _aliado = refAliado.current.getBoundingClientRect().height;
            let _quienes = refQuienes.current.getBoundingClientRect().height;
            let _proyectos = refProyectos.current.getBoundingClientRect().height;
            let _tecnologia = refTecnologia.current.getBoundingClientRect().height;
            let _herramientas = refHerramientas.current.getBoundingClientRect().height;
            let _team = refTeam.current.getBoundingClientRect().height;


            let _rot = (window.scrollY * 360) / (_entendemos + _aliado + _quienes + _proyectos + _tecnologia + _herramientas+ _team);

            refFlair.current.style.transform = `rotate(${_rot}deg)`;

        }

        return _ => {
            window.removeEventListener('scroll', OnScroll);
        }

    }, [])

    return (
        <div className={`${styles.homeWrapper} ${(loader)?styles.loader:""}`}>
            {
                (loader) &&
                <Loader />
            }
            {
                (!loader) &&
                <>
                    <Nav />
                    <Entendemos forwardRef={refEntendemos} />
                    <AliadoTec forwardRef={refAliado} />
                    <QuienesSomos forwardRef={refQuienes} />
                    <Tecnologia forwardRef={refTecnologia} />
                    <Herramientas forwardRef={refHerramientas} />
                    <Proyectos forwardRef={refProyectos} />
                    <CardProvider><Team forwardRef={refTeam} /></CardProvider>
                    <Contact forwardRef={refContacto} />
                    <BACK_FLAIR forwardRef={refFlair} />
                    
                </>
            }
            <VIDEO_WAVE />
        </div>
    );
}
export default Home;
