import {React, useCallback} from 'react'
import styles from './Tecnologia.module.css'
import canalesIcons from '../../assets/img/tecnologia/canales.svg'
import evaluacionIcons from '../../assets/img/tecnologia/evaluacion.svg'
import simuladoresIcons from '../../assets/img/tecnologia/simuladores.svg'
import solucionesIcons from '../../assets/img/tecnologia/soluciones.svg'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import * as Framer from '../frameVariants/FrameVariants'; 

export default function Tecnologia(props) {

    const {forwardRef}=props;
    const [inViewRef, inView] = useInView({ threshold: .5 });

    const setRefs = useCallback(
        (node) => {
            forwardRef.current = node;
            inViewRef(node);
        }, [inViewRef]);

    return (
        <div ref={setRefs} className={styles.wrapperTecnologia}>
            <motion.div className={styles.contentTecno}
            variants = { Framer.FromBottom }
            initial = "hidden"
            animate = {inView ? "visible" : ""}
            transition ={ Framer.HalfDelay }>
                <h1>¿Por qué tecnología creativa?</h1>
                <p>La clave para lograr nuestra premisa de adaptar la tecnología a las necesidades de nuestros clientes, está en crear un entorno tecnológico creativo.</p>
                <h4><span className={styles.creatividad}>Creatividad</span>, <span className={styles.disrupcion}>disrupción</span> e <span className={styles.innovacion}>innovación </span>para lograr resultados</h4>
            </motion.div>
            <motion.div className={styles.iconsTecno}
            variants = { Framer.FromRight }
            initial = "hidden"
            animate = {inView ? "visible" : ""}
            transition ={ Framer.HalfDelay }>
                <div className={styles.iconBox}>
                    <div className={styles.icon}>
                        <img src={canalesIcons} alt="canales"/>
                    </div>
                    <p>
                        Generamos ahorro, mejoramos procesos, asistimos en la transformación digital y generamos canales de comunicación.
                    </p>
                </div>
                <div className={styles.iconBox}>
                    <div className={styles.icon}>
                        <img src={evaluacionIcons} alt="evaluación"/>
                    </div>
                    <p>
                        Soluciones para procesos de capacitación, onboarding, evaluación y selección de personal, auditoría, canales de comunicación e interacción.
                    </p>
                </div>
                <div className={styles.iconBox}>
                    <div className={styles.icon}>
                        <img src={solucionesIcons} alt="soluciones"/>
                    </div>
                    <p>
                        Soluciones en comunicación digital, estrategias, redes sociales, sitios web, etc.
                    </p>
                </div>
                <div className={styles.iconBox}>
                    <div className={styles.icon}>
                        <img src={simuladoresIcons} alt="simuladores"/>
                    </div>
                    <p>
                        Hemos desarrollado desde sitios web hasta simuladores con realidad virtual, seguro te podemos ayudar.
                    </p>
                </div>
            </motion.div>
        </div>
    )
}
