import React from 'react';
import { Engine, Scene} from 'react-babylonjs';
import styles from "./CardTeam.module.css";
import { Color3 } from '@babylonjs/core';
import '@babylonjs/loaders';
import { AvatarModel } from './AvatarModel';
export default function RenderModel(props) {

  const { slug } = props;
  let baseUrl = "./assets/modelos/";

  return (
    <div className={styles.wrapperModel}>
      <Engine antialias={true} adaptToDeviceRatio={true} canvasId='model-canvas' >
        <Scene clearColor={new Color3.FromHexString("#0E4562")} >
          <AvatarModel baseUrl={baseUrl} slug={slug} />
        </Scene>
      </Engine>
    </div>
  )
}



